@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

.logo {
  font-family: 'Pacifico', cursive;
}

@layer base {
  p {
    @apply font-[Rubik];
  }

  /* * {
    @apply bg-[#ffe4b4];
  } */
}

.bg-pattern {
  background-color: #fff5e4;
  opacity: 0.8;
  background-image: radial-gradient(
      #ff5600 0.8500000000000001px,
      transparent 0.8500000000000001px
    ),
    radial-gradient(#ff5600 0.8500000000000001px, #fff5e4 0.8500000000000001px);
  background-size: 34px 34px;
  background-position: 0 0, 17px 17px;
  z-index: -1;
}

.z-middle {
  z-index: 30;
}

.z-front {
  z-index: 99;
}

.z-back {
  z-index: -99;
}

/* SideBar CSS Feature */

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #e88c8c -278.56%,
    #ffd82b -78.47%,
    #ff6842 91.61%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.font-poppins {
  font-family: 'Quicksand', sans-serif;
}

.font-rubik {
  font-family: 'Rubik', sans-serif;
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.orange__gradient {
  background: linear-gradient(180deg, rgba(255, 123, 111, 0) 0%, #fa7202 100%);
  filter: blur(123px);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(107, 58, 255, 0) 0%, #2701ff 100%);
  filter: blur(123px);
}

.darkblue__gradient {
  background: linear-gradient(120deg, rgba(1, 12, 82, 0) 20%, #0017e2 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #ff59fc 0%, #ff0b4c 100%);
  filter: blur(900px);
}

.violet__gradient {
  background: linear-gradient(90deg, #fa84f8 0%, #ff06fb 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

/* Underline Animation Styles */
/* link link-underline link-underline-black */

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(rgb(2, 56, 251), rgb(0, 138, 243));
}

.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}

/* Navbar Dropdown Menu css */
nav ul li {
  position: relative;
}

nav ul li:hover > ul {
  display: block;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9fafb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

nav ul ul li {
  width: 100%;
}

nav ul ul a {
  color: #444444;
  display: block;
  padding: 10px;
  font-size: 14px;
}

nav ul ul a:hover {
  color: #f98151;
}
